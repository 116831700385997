export function useUtilsApi() {
  const { get, post } = useApi()

  function notify(message) {
    return post('utils/notify', message)
  }

  function globalSearch(params) {
    return get('utils/search', params)
  }

  function getEstimate(params) {
    return get(`utils/estimate`, params)
  }

  return {
    notify,
    globalSearch,
    getEstimate,
  }
}
